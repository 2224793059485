<template>
  <div>
    <div class="pa-2" v-for="(dm, index) in documentMetadata" :key="dm.metadata.key">
      <DocumentMetadata
        v-if="shouldHide(dm)"
        :documentMetadata="dm"
        :textSelection="textSelection"
        :focusOnLoad="index === focusIndex"
        :onClickToFill="() => onClickToFill(index)"
        :onPartyChanged="onPartyChanged"
      />
    </div>
  </div>
</template>

<script>
import DocumentMetadata from "@/components/DocumentMetadata";
import metadataKey from "@/constants/metadataKey";

export default {
  name: "DocumentMetadataList",

  components: {
    DocumentMetadata,
  },

  props: {
    documentMetadata: Array,
    textSelection: Object,
    onLastMetadataClickedToFill: Function,
    onPartyChanged: Function,
  },

  data() {
    return {
      focusIndex: 0,
    };
  },

  methods: {
    onClickToFill(index) {
      let isLastHeaderMetadata = index === this.documentMetadata.length - 1;

      if (isLastHeaderMetadata && this.onLastMetadataClickedToFill) {
        this.onLastMetadataClickedToFill();
      } else {
        this.focusIndex = index + 1;
      }

      setTimeout(() => {
        this.focusIndex = -1;
      }, 200);
    },
    shouldHide(dm) {
      return dm.metadata.key !== metadataKey.DivisionOrderResponseStatus;
    },
  },
};
</script>

<template>
  <v-dialog v-model="show" :max-width="800" :persistent="true" :fullscreen="isMobile">
    <v-card v-bind:style="{ 'min-height': !isMobile ? '550px' : '' }">
      <v-card-title class="pa-4" v-if="documentType">
        <v-icon left>mdi-send</v-icon> Send {{ documentType.name }}
        <v-spacer />
        <v-btn icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-form ref="form" class="pl-4">
        <v-card-text>
          <v-row>
            <v-col>
              <PartyMetadata
                :focusOnLoad="focusOnParty"
                ref="party"
                :rules="requiredRule"
                :documentMetadata="partyMetadata"
                :partyTypeId="5" :showOrgUnit="true"
              />
              <vue-dropzone
                v-if="dropzoneOptions && partyMetadata.value && documentTypeId"
                ref="myVueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                @vdropzone-sending="beforeSending"
                @vdropzone-complete="completeSending"
                :useCustomSlot="true"
                class="mt-4"
                data-cy="drag-and-drop-zone"
              >
                <div class="dropzone-custom-content">
                  <span class="dropzone-custom-title">
                    <v-icon left>mdi-upload</v-icon>
                    <span>Select a file or drag it here</span>
                  </span>
                </div>
              </vue-dropzone>
              <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            </v-col>
          </v-row>
          <v-row class="pt-4">
            <v-spacer />
            <v-btn class="mr-3" v-show="!disableNext" depressed color="primary" @click="next()" data-cy="next">
              Next
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import batchService from "@/services/batchService";
import documentTypeService from "@/services/documentTypeService";
import { API_URL } from "../env";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapState, mapGetters } from "vuex";
import PartyMetadata from "@/components/metadata/PartyMetadata";
import Auth0Mixin from "../views/Auth0Mixin";
import responsiveService from "@/services/responsiveService";

const requiredRule = [v => !!v || "Required"];

export default {
  components: { vueDropzone: vue2Dropzone, PartyMetadata },
  mixins: [Auth0Mixin],

  props: {
    show: Boolean,
    documentTypeId: Number,
    cancel: Function,
  },

  data() {
    return {
      loading: false,
      focusOnParty: true,
      requiredRule,
      dropzoneOptions: undefined,
      fuck: false,
      fileUploadData: {
        batchName: null,
        userId: null,
        folderId: 1,
        numberOfDocuments: 1,
        returnBeforeOcr: false,
      },
      documentType: undefined,
      partyMetadata: {
        value: undefined,
        metadata: {
          name: "Send To",
          key: "sendTo",
        },
      },
    };
  },

  computed: {
    ...mapGetters("auth0Store", ["selectedCompanyId", "auth0Token", "user"]),

    ...mapState("documentTypeStore", ["documentTypes"]),

    disableNext() {
      return this.loading || !this.partyMetadata.value;
    },

    validForm() {
      if (!this.$refs.myVueDropzone) return false;
      return this.$refs.myVueDropzone.getQueuedFiles() > 0;
    },

    isMobile() {
      return responsiveService.isMobile(this.$vuetify.breakpoint);
    },
  },

  watch: {
    show() {
      this.focusOnParty = this.show;
    },

    documentTypeId() {
      this.init();
    },
  },

  methods: {
    async init() {
      this.dropzoneOptions = {
        url: `${API_URL}/documents/upload`,
        addRemoveLinks: true,
        parallelUploads: 1,
        maxFiles: 1,
        thumbnailWidth: 150,
        maxFilesize: 500,
        autoProcessQueue: false,
        headers: {
          "Company-Id": this.selectedCompanyId,
          Authorization: `Bearer ${this.auth0Token}`,
        },
      };

      this.documentType = await documentTypeService.getFromTemplate(this.documentTypeId);
    },

    next() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.$refs.myVueDropzone.getQueuedFiles().length === 0) {
        this.$root.$snackbar.error("Please select a file to send");
        return;
      }

      this.fileUploadData.batchName = batchService.generateBatchName();
      this.fileUploadData.receivingPartyId = this.partyMetadata.value;
      this.fileUploadData.documentTypeId = this.documentType.id;
      this.fileUploadData.userId = this.user.id;

      this.dropzoneOptions.headers['Authorization'] = `Bearer ${this.auth0Token}`;
      this.$refs.myVueDropzone.processQueue();

      this.loading = true;
    },

    beforeSending(file, xhr, formData) {
      if (this.fileUploadData) {
        formData.append("fileUploadData", JSON.stringify(this.fileUploadData));
      }
    },

    async completeSending() {
      if (!this.fileUploadData.batchName) return;

      if (this.$refs.myVueDropzone.getQueuedFiles().length === 0) {
        let docIds = await batchService.getBatchDocumentIds(this.fileUploadData.batchName, this.fileUploadData.userId);

        if (docIds.length === 1) {
          if (this.fileUploadData.returnBeforeOcr) {
            this.loading = false;
            this.cancel();
            this.$refs.myVueDropzone.removeAllFiles();
          } else {
            this.$router.push({
              path: `/documents/${docIds[0]}`,
            });
          }
        } else {
          this.$router.push({ path: `/documents/batch/${encodeURIComponent(this.fileUploadData.batchName)}` });
        }
      }
    },
  },
};
</script>

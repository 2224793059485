const InviteExpiredValue = "inviteExpired";
const ConnectionRequestExpired = "connectionRequestExpired";
export default {
  isDisconnected: party => !!party.isDisconnected,
  isConnected: party => !!party.connectedCompanyId,
  isOnNetwork: party => !!party.suggestedCompanyId && !party.connectedCompanyId && !party.isInviteSent,
  isNotOnNetwork: party => !party.suggestedCompanyId && !party.connectedCompanyId && !party.isInviteSent && !party.isDisconnected,
  IsInviteSent: party =>
    party.partyStatus !== InviteExpiredValue &&
    party.isInviteSent &&
    !party.connectedCompanyId &&
    !party.isDisconnected &&
    !party.suggestedCompanyId,
  IsConnecting: party =>
    party.partyStatus !== ConnectionRequestExpired &&
    party.isInviteSent &&
    !!party.suggestedCompanyId &&
    !party.connectedCompanyId,
  isInviteExpired: party => {
    return party.partyStatus === InviteExpiredValue;
  },
  isConnectionExpired: party => party.partyStatus === ConnectionRequestExpired,
};

<template>
  <v-app-bar app color="white" clipped-left fixed >
    <div class="d-flex align-center" v-if="selectedCompanyId">
      <img class="hidden-sm-and-down" style="cursor:pointer" id="logo-src" @click="goTo('Home')" v-bind:src="logoData" />
    </div>

    <v-autocomplete
      :items="searchMatches"
      filled
      dense
      rounded
      auto-select-first
      prepend-inner-icon="mdi-magnify"
      class="mt-4 searchbar"
      :loading="loading"
      :search-input.sync="searchQuery"
      hide-no-data
      placeholder="Search all documents..."
      persistent-placeholder
      :filter="() => true"
      @change="onSelect"
    >
      <template v-slot:item="data">
        <v-list-item-content class="match-text">
          <v-list-item-title>
            <v-icon v-show="data.item.isSearch">mdi-magnify</v-icon>
            <v-icon v-show="!data.item.isSearch">mdi-file-document</v-icon>
            {{ data.item.title }}
          </v-list-item-title>
          <v-list-item-subtitle class="ml-4 pl-4" v-html="data.item.matchText"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>

    <v-spacer></v-spacer>
    
    <v-bottom-navigation style="width: 150px; box-shadow: none">
      <v-btn @click="goTo('Connections')">
        <span>Connections</span>  
        <v-icon>mdi-domain</v-icon>
        <v-badge class="pl-2" v-show="availableConnections > 0" color="red" :content="availableConnections" style="margin: 10px 0 0 20px;"></v-badge>
      </v-btn>
    </v-bottom-navigation>
  
    <h3 class="text-h6 ma-3">{{ getCompanyName() }}</h3>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <div class="account-menu">
          <v-btn icon large v-on="on" v-if="!$auth.isAuthenticated && !$auth.loading">
            <v-icon>account_circle</v-icon>
          </v-btn>
          <v-avatar
            v-on="on"
            v-if="$auth.isAuthenticated && !$auth.loading"
            data-cy="profile"
            color="gray"
            v-bind:class="{ 'global-user': isGlobalUser }"
            size="48"
          >
            <v-img :src="$auth.user.picture"></v-img>
          </v-avatar>
        </div>
      </template>
      <v-list>
        <v-list-item @click="showChangeCompanyDialog = true" v-if="$auth.isAuthenticated">
          <v-icon>mdi-swap-horizontal</v-icon>
          <v-list-item-title class="pl-2">Change Account</v-list-item-title>
        </v-list-item>
        <v-list-item @click="goTo('Notifications')" v-if="$auth.isAuthenticated">
          <v-icon>mdi-bell-outline</v-icon>
          <v-list-item-title class="pl-2">Notifications({{unreadNotifications}})</v-list-item-title>
        </v-list-item>
        <v-list-item @click="goTo('Profile')" v-if="$auth.isAuthenticated">
          <v-icon>mdi-account-details</v-icon>
          <v-list-item-title class="pl-2">Edit my Profile</v-list-item-title>
        </v-list-item>
        <v-list-item data-cy="logout" @click="logout" v-if="$auth.isAuthenticated">
          <v-icon>input</v-icon>
          <v-list-item-title class="pl-2">Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <ChangeCompanyDialog
      :show="showChangeCompanyDialog"
      :cancel="() => (showChangeCompanyDialog = false)"
    ></ChangeCompanyDialog>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import localStorageService from "@/services/localStorageService.js";
import auth0Config from "@/auth0Config";
import { URL } from "@/env";
import filterService from "@/services/filterService.js";
import partyService from "@/services/partyService.js";
import typeaheadService from "@/services/typeaheadService";
import ChangeCompanyDialog from "@/dialogs/ChangeCompanyDialog.vue";

export default {
  props: {
  },

  components: {
    ChangeCompanyDialog,
  },

  data() {
    return {
      searchMatches: [],
      availableConnections: 0,
      searchQuery: "",
      highlight: filterService.highlight,
      loading: false, 
      showChangeCompanyDialog: false,
    };
  },

  watch: {
    async searchQuery(query) {
      if (!query) return;

      this.loading = true;
      let typeaheadResults = await typeaheadService.search(query);
      this.searchMatches = [{ title: `Search for "${query}"`, isSearch: true }, ...typeaheadResults];

      this.loading = false;

      return query;
    },
  },

  methods: {
    goTo(route){
      this.$router.push({ name: route });
    },
    getAvailableConnections(){
      partyService.getPartyNetworkMatches().then(parties => {
        this.availableConnections = parties.length;
       }
      );
    },
    getCompanyName() {
      var company = this.companies.find(x => x.id == this.selectedCompanyId);

      if (company) {
        return company.name;
      }
    },
    async logout() {
      localStorageService.clear();
      await this.$auth.logout();
      const encodedUrl = encodeURIComponent(URL);
      window.location.href = `https://${auth0Config.domain}/v2/logout?client_id=${auth0Config.clientId}&returnTo=${encodedUrl}`;
    },
    onSelect(item) {
      if (item.isSearch) return this.onSearch();

      this.searchMatches = [];
      this.searchQuery = "";
      this.$router.push({ name: "Document", params: { id: `${item.id}` } });
    },
    onSearch() {
      this.searchMatches = [];
      this.$router.push(`/search?q=${encodeURIComponent(this.searchQuery)}`);
    },
  },
  
  async created() {
    this.getAvailableConnections();
  },

  computed: {
    adminOperations(){
      return [{ icon: "domain", title: "Connections", to: "/admin/connections" }]
    },
    ...mapGetters("auth0Store", ["companies", "isGlobalUser", "selectedCompanyId"]),
    ...mapGetters("logoStore", ["logoData"]),
    ...mapGetters("userProfile", ["unreadNotifications"]),
  },
};
</script>
<style>
.match-text em {
  background: #eeed8b;
  font-style: normal;
}

.searchbar {
  padding-top: 12px !important;
}
</style>

import httpService from "@/services/httpService";

export default {
  getTimezones: async () => httpService.get(`/servicesLookup/Timezones`),
  getCountries: async () => httpService.get(`/servicesLookup/Countries`),
  getAllStates: async () => httpService.get(`/servicesLookup/AllStates`),
  getAllTaxes: async () => httpService.get(`/servicesLookup/AllTaxTypes`),
  getStates: async countryCode => httpService.get(`/servicesLookup/States/${countryCode}`),
  getTaxTypes: async countryCode => httpService.get(`/servicesLookup/TaxTypes/${countryCode}`),
  getLocations: async () => httpService.get(`/servicesLookup/Locations`),
  getAllTaxTypesByCountry: async () => httpService.get(`/servicesLookup/AllTaxTypesByCountry`),
  getAllStatesByCountry: async () => httpService.get(`/servicesLookup/AllStatesByCountry`),
};

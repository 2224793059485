import validationService from "@/services/validationService";

export default {
  requiredRule: v => (!!v && v.length > 0) || `Required`,
  emailRule: v => validationService.isvalidEmail(v) || "Must be a valid email",
  numeric: v => validationService.isNumber(v) || "Must be a number",
  companyNameRule: v => {
    let stripNonAzureContainerChar = v ? v.replace(/[^a-z0-9]/i, "") : "";
    return (!!v && stripNonAzureContainerChar.length > 2) || "Legal Name must have at least 3 alphanumeric characters";
  },
  zipCodeRule: v => validationService.isUSZipCode(v) || "Must be a 5 or 9 digit code",
  postalCodeRule: v => validationService.isCAPostalCode(v) || "Must be a 6 character code arranged in the form 'ANANAN', where 'A' represents an alphabetic character and 'N' represents a numeric character",
};

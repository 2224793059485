<template>
  <v-container fluid>
    <v-card class="elevation-1 card pa-4">
      <div v-if="!result">
        <h3 class="headline">Upload Connection List</h3>
        <h4 class="subtitle-1 mt-4">Step 1. Download Excel Template</h4>
        <v-row class="pl-4 pt-2">
          <a href="/excel/ConnectionListTemplate.xlsx">ConnectionListTemplate.xlsx</a>
        </v-row>
        <h4 class="subtitle-1 mt-4 mb-4">Step 2. Upload</h4>
        <v-file-input
          ref="inputUpload"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          placeholder="Click to Upload Excel File"
          prepend-icon="mdi-file-excel"
          @change="selectFile"
          filled
          dense
        ></v-file-input>
      </div>
      <div v-if="result">
        <h4 class="headline">{{ resultTitle }}</h4>
        <v-row class="mt-4 ml-2">
          <v-btn @click="clearResult()">
            <v-icon left>mdi-arrow-left</v-icon>
            Upload Another File
          </v-btn>
        </v-row>
        <v-data-table
          disable-pagination
          :hide-default-footer="true"
          :must-sort="true"
          :no-data-text="''"
          :headers="headers"
          :items="result"
          :search="searchQuery"
        >
          <template v-slot:item.errorMessage="{ item }">
            <div>
              <v-icon v-show="!item.success" color="red">mdi-alert-circle</v-icon>
              <v-icon v-show="item.success" color="green">mdi-check-bold</v-icon>
              <span v-bind:class="{ 'green--text': item.success, 'red--text': !item.success, 'pl-4': true }">{{
                item.errorMessage
              }}</span>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import httpService from "@/services/httpService";
export default {
  data() {
    return {
      result: null,
      searchQuery: "",
      headers: [
        { text: "Message", value: "errorMessage" },
        { text: "Number", value: "entity.number" },
        { text: "Name", value: "entity.name" },
        { text: "Address Line One", value: "entity.addressLineOne" },
        { text: "Address Line Two", value: "entity.addressLineTwo" },
        { text: "City", value: "entity.city" },
        { text: "State", value: "entity.state" },
        { text: "Zip", value: "entity.zipCode" },
        { text: "Phone #", value: "entity.phone" },
      ],
    };
  },

  computed: {
    resultTitle() {
      let success = this.result.filter(r => r.success).length;
      let failed = this.result.length - success;

      return `${success} succeeded, ${failed} failed`;
    },
  },
  methods: {
    async selectFile(file) {
      this.result = await httpService.postFile("/party/upload", file);
    },
    clearResult() {
      this.result = null;
    },
  },
};
</script>

<template>
  <v-container fluid v-if="user">
    <LoadingProgress :loading="loading" />
    <v-card class="elevation-1 card pa-4 card-form">
      <v-form ref="form">
        <v-card-text>
          <v-row>
            <h3 class="headline mb-4 ml-2">{{ isCreate ? "Create" : "Edit" }} User</h3>
            <v-spacer />
            <div v-show="!isCreate">
              <ActionDropdown :actions="actions" :performAction="performAction" />
            </div>
          </v-row>
          <EntityForm :entity="user" :inputFields="USER_FIELDS" />
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="save()" :disabled="loading" large color="primary" depressed>Save</v-btn>
        <v-btn @click="cancel()" :disabled="loading" large depressed>Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import userService from "@/services/userService";
import roleService from "@/services/roleService";
import validationService from "@/services/validationService";
import EntityForm from "@/components/EntityForm";
import ActionDropdown from "@/components/ActionDropdown";
import Auth0Mixin from "../Auth0Mixin";
import userTypeList from "@/constants/userTypeList";
import LoadingProgress from "@/components/LoadingProgress";

const ACTION_DELETE = "Delete";
const ACTION_CHANGE_EMAIL = "Change Email Address";
const ACTION_SAVE = "Save";

const requiredRule = [v => !!v || "Required"];
const requiredEmailRule = existing => [
  v =>
    (!!v && validationService.isvalidEmail(v) && v !== existing) ||
    "Must be a valid e-mail and different from existing email",
];

const mainRoleField = { label: "Main Role", rules: requiredRule, attribute: "mainRole", type: "select", items: [] };
const additionalRolesField = {
  label: "Additional Roles",
  attribute: "additionalRoles",
  type: "multi-select",
  items: [],
};

const USER_FIELDS = [
  { label: "Email", attribute: "emailAddress", rules: requiredRule, type: "text" },
  { label: "Title", attribute: "title", type: "text", maxlength: 25 },
  { label: "First Name", attribute: "firstName", rules: requiredRule, type: "text" },
  { label: "Last Name", attribute: "lastName", rules: requiredRule, type: "text" },
  { label: "Phone", attribute: "phoneNumber", maxlength: 50, type: "number" },
  mainRoleField,
  // We are hiding this for now (see #41120)
  // additionalRolesField,
  { label: "Active", attribute: "isActive", type: "checkbox" },
];

export default {
  name: "Users",
  mixins: [Auth0Mixin],
  components: { EntityForm, ActionDropdown, LoadingProgress },
  data() {
    return {
      user: null,
      isCreate: false,
      loading: false,
      actions: [ACTION_SAVE, ACTION_DELETE, ACTION_CHANGE_EMAIL],
      USER_FIELDS,
      requiredRules: [v => !!v || "Required"],
      roles: [],
    };
  },

  methods: {
    async init() {
      this.isCreate = this.$route.params.id === "create";
      this.roles = await roleService.getAll();

      if (this.isCreate) {
        this.user = {
          mainRole: 3,
          additionalRoles: [],
          userType: userTypeList[0],
          isActive: true,
        };
      } else {
        this.user = await userService.getUser(this.$route.params.id);
        let selectedRoles = (await userService.getRoles(this.user.id)).items;
        let selectedRolesArray = selectedRoles.map(sr => sr.roleId);
        let mainRole = this.roles.find(r => r.isMainRole && selectedRolesArray.indexOf(r.id) >= 0);
        this.user.mainRole = mainRole ? mainRole.id : null;
        this.user.additionalRoles = this.roles
          .filter(r => !r.isMainRole && selectedRolesArray.indexOf(r.id) >= 0)
          .map(r => r.id);
      }

      USER_FIELDS.find(x => x.attribute === "emailAddress").readonly = !this.isCreate;

      mainRoleField.items = this.roles.filter(r => r.isMainRole);
      additionalRolesField.items = this.roles.filter(r => !r.isMainRole).sort((a, b) => a.name.localeCompare(b.name));
    },

    async performAction(action) {
      if (action === ACTION_SAVE) {
        this.save();
      } else if (action === ACTION_DELETE) {
        try {
          await userService.deleteUser(this.user.id);
          this.$root.$snackbar.message(`User Deleted`);
          this.$router.push(`/admin/users`);
        } catch (err) {
            this.$root.$snackbar.error("Error deleting the user. Please contact support.");
        }
      } else if (ACTION_CHANGE_EMAIL) {
        let email = await this.$root.$inputDialog.open(
          "New email address",
          "E-mail",
          requiredEmailRule(this.user.emailAddress)
        );

        this.user.emailAddress = email;
        await userService.updateEmailAddress(this.user);

        this.$root.$snackbar.message(`User email address changed`);
        this.$router.push(`/admin/users`);
      }
    },

    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      // we need to combine main role with additional roles into 1 list
      this.user.userRoles = [{ roleId: this.user.mainRole, userId: this.user.id }].concat(
        (this.user.additionalRoles || []).map(r => ({ roleId: r, userId: this.user.id }))
      );

      this.loading = true;

      try {
        if (this.isCreate) {
          this.user.isTemporaryPassword = true;
          await userService.createUser(this.user);
        } else {
          await userService.updateUser(this.user);
        }
      } catch (err) {
        this.loading = false;
        if (err.response.data.status === 409) {
          var error = err.response.data.error;
          var message =
            error.errorCode === "120-020"
              ? "The user you are attempting to create already exists in another company with a different name. Please revise the name and try again. If you continue to have issues, please contact support who can assist you further."
              : "User with the same email already exist.";
          this.$root.$snackbar.error(message);
        } else {
          this.$root.$snackbar.error(err.response.data.error.message);
        }
        return;
      }

      this.$root.$snackbar.message(`User ${this.isCreate ? "Created" : "Saved"}`);
      this.$router.push(`/admin/users`);
    },
    cancel() {
      this.$router.push(`/admin/users`);
    },
  },
};
</script>
<style scoped>
.onboarding {
  border: 2px solid #eee;
}
</style>

<template>
  <v-snackbar v-model="show" top center :color="color" :vertical="isAlert" :timeout="timeout" @click.native="clickHandler()">
    <div v-if="title">
      <b> {{ title }} </b>
    </div>
    {{ text }}
    <div v-if="isSystemNotification && showMoreCount" style="text-align: right">
      +{{this.systemNotifications.length}} more
    </div>
    <template v-slot:action="{ attrs }" v-if="isAlert || isSystemNotification">
      <v-btn :color="closeBtnColor" text v-bind="attrs" @click="closeHandler()">
        {{ closeBtnText }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import notificationService from "../services/notificationService";

export default {
  data: () => ({
    show: false,
    text: "",
    title: null,
    isAlert: false,
    isSystemNotification: false,
    color: "info",
    vertical: false,
    timeout: 5000,
    systemNotifications: [],
    closeBtnText: "Close",
    closeBtnColor: "indigo",
    closeHandler: () => {},
    clickHandler: () => {},
  }),
  computed: {
    showMoreCount() {
      return this.systemNotifications.length > 0;
    }
  },
  methods: {
    message(text) {
      this.show = true;
      this.text = text;
      this.isAlert = false;
      this.isSystemNotification = false;
      this.title = null;
      this.timeout = 5000;
      this.color = "info";
      this.clickHandler = () => {};
    },
    success(text) {
      this.show = true;
      this.text = text;
      this.isAlert = false;
      this.isSystemNotification = false;
      this.title = null;
      this.timeout = 5000;
      this.color = "success";
      this.clickHandler = () => {};
    },
    error(text) {
      this.show = true;
      this.text = text;
      this.title = null;
      this.isAlert = false;
      this.isSystemNotification = false;
      this.timeout = 5000;
      this.color = "error";
      this.clickHandler = () => {};
    },
    exception(ex, showCloseButton = false) {
      this.show = true;
      this.text = ex.response.data.error.message;
      this.isAlert = showCloseButton;
      this.isSystemNotification = false;
      this.title = null;
      this.timeout = 5000;
      this.color = "error";
      this.closeBtnColor = "indigo";
      this.closeBtnText = "Close";
      this.closeHandler = () => { this.show = false; };
      this.clickHandler = () => {};
    },
    warn(text, title = null, isAlert = false) {
      this.show = true;
      this.text = text;
      this.title = title;
      this.isAlert = isAlert;
      this.isSystemNotification = false;
      this.timeout = isAlert ? -1 : 5000;
      this.color = "orange darken-2";
      this.closeBtnColor = "indigo";
      this.closeBtnText = "Close";
      this.closeHandler = () => { this.show = false; };
      this.clickHandler = () => {};
    },
    messageWithUrl(text, url) {
      this.show = true;
      this.text = text;
      this.isAlert = false;
      this.isSystemNotification = false;
      this.title = null;
      this.timeout = 5000;
      this.color = "info";
      this.clickHandler = () => {
        window.open(url, "_blank");
      };
    },
    systemNotification(message, title, notificationId) {
      // If a system notification is currently being shown, add the notification to the queue to be shown after the current one is dismissed
      if (this.show && this.isSystemNotification) {
        this.systemNotifications.push({message, title, notificationId});
      }
      else {
        this.show = true;
        this.text = message;
        this.isAlert = false;
        this.isSystemNotification = true;
        this.title = title;
        this.timeout = 0;
        this.color = "info";
        this.closeBtnColor = "white";
        this.closeBtnText = "Dismiss";
        this.closeHandler = () => {
          // Mark the currently showing notification as read
          notificationService.markNotificationAsRead(notificationId);
          
          // If there are system notifications in the queue, display the newest one instead of close 
          if (this.systemNotifications.length > 0) {
            this.show = false;
            // Wait a second to show the next notification so the first one has time to close first
            setTimeout(() => {
              let poppedElement = this.systemNotifications.pop();
              this.text = poppedElement.message;
              this.title = poppedElement.title;
              notificationId = poppedElement.notificationId;
              this.show = true
            }, 1000);
          } else {
            this.show = false;
          }
        };
        this.clickHandler = () => {}; 
      }
    }
  },
};
</script>

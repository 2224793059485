<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs"> + Add Payment </v-btn>
    </template>
    <v-card>
      <v-card-title>Add Payment</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <EntityForm :inputFields="preferencesFields" :entity="paymentData"></EntityForm>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="addPayment(paymentData)" color="primary">Ok</v-btn>
        <v-btn @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ValidationRules from "@/validationRules";
import EntityForm from "@/components/EntityForm";
let createEmptyPaymentData = documentId => ({
  amount: undefined,
  checkNumber: null,
  description: null,
  isCancellation: false,
  transactionDate: new Date(),
  paymentId: null,
  documentId: documentId,
})
export default {
  name: "PaymentDialog",
  props: {
    documentId: Number,
    addPaymentHandler: Function,
  },
  components: {
    EntityForm,
  },
  data() {
    return {
      dialog: false,
      isValid: false,
      preferencesFields: [
        { label: "Is Cancellation", attribute: "isCancellation", type: "checkbox" },
        {
          label: "Payment Amount",
          attribute: "amount",
          type: "text",
          rules: [ValidationRules.requiredRule, ValidationRules.numeric],
        },
        { label: "TransactionDate", attribute: "transactionDate", type: "date" },
        { label: "Check Number", attribute: "checkNumber", type: "text" },
        { label: "Description", attribute: "description", type: "text" },
        { label: "Payment ID", attribute: "paymentId", type: "text" },
      ],
      paymentData: createEmptyPaymentData(this.documentId),
    };
  },
  methods: {
    cancel() {
      this.dialog = false;
      this.paymentData = createEmptyPaymentData(this.documentId);
    },
    async addPayment() {
      if (!this.$refs.form.validate()) return;
      await this.addPaymentHandler(this.paymentData);
      this.paymentData = createEmptyPaymentData(this.documentId);
      this.dialog = false;
    },
  },
};
</script>

<template>
  <div>
    <TextMetadata
      :isLineItem="isLineItem"
      :textSelection="textSelection"
      :focusOnLoad="focusOnLoad"
      :documentMetadata="documentMetadata"
      :onClickToFill="onClickToFill"
      v-if="isMatch([metadataType.Text, metadataType.TextArea])"
    />
    <NumericMetadata
      :isLineItem="isLineItem"
      :textSelection="textSelection"
      :focusOnLoad="focusOnLoad"
      :documentMetadata="documentMetadata"
      :onClickToFill="onClickToFill"
      v-if="isMatch([metadataType.Currency, metadataType.Numeric])"
    />
    <PartyMetadata
      :isLineItem="isLineItem"
      :textSelection="textSelection"
      :focusOnLoad="focusOnLoad"
      :documentMetadata="documentMetadata"
      :onClickToFill="onClickToFill"
      :partyTypeId="partyTypeId"
      :onPartyChanged="onPartyChanged"
      v-if="
        isMatch([
          metadataType.Vendor,
          metadataType.Bank,
          metadataType.Operator,
          metadataType.Owner,
          metadataType.Purchaser,
        ])
      "
    />
    <DateMetadata
      :isLineItem="isLineItem"
      :textSelection="textSelection"
      :focusOnLoad="focusOnLoad"
      :documentMetadata="documentMetadata"
      :onClickToFill="onClickToFill"
      v-if="isMatch(metadataType.Date)"
    />
    <DateTimeMetadata
      :isLineItem="isLineItem"
      :textSelection="textSelection"
      :focusOnLoad="focusOnLoad"
      :documentMetadata="documentMetadata"
      :onClickToFill="onClickToFill"
      v-if="isMatch(metadataType.DateTime)"
    />
    <OrgUnitMetadata
      :isLineItem="isLineItem"
      :textSelection="textSelection"
      :focusOnLoad="focusOnLoad"
      :documentMetadata="documentMetadata"
      :onClickToFill="onClickToFill"
      v-if="isMatch(metadataType.OrgUnit)"
    />
    <ListMetadata
      :isLineItem="isLineItem"
      :textSelection="textSelection"
      :focusOnLoad="focusOnLoad"
      :documentMetadata="documentMetadata"
      :onClickToFill="onClickToFill"
      v-if="isMatch(metadataType.List)"
    />
  </div>
</template>

<script>
import metadataType from "@/constants/metadataType";
import partyType from "@/constants/partyType";
import TextMetadata from "@/components/metadata/TextMetadata";
import NumericMetadata from "@/components/metadata/NumericMetadata";
import PartyMetadata from "@/components/metadata/PartyMetadata";
import DateMetadata from "@/components/metadata/DateMetadata";
import OrgUnitMetadata from "@/components/metadata/OrgUnitMetadata";
import ListMetadata from "@/components/metadata/ListMetadata";
import DateTimeMetadata from "@/components/metadata/DateTimeMetadata";

const metadataTypeToParty = {};
metadataTypeToParty[metadataType.Vendor] = partyType.VENDOR;
metadataTypeToParty[metadataType.Bank] = partyType.BANK;
metadataTypeToParty[metadataType.Operator] = partyType.OPERATOR;
metadataTypeToParty[metadataType.Owner] = partyType.OWNER;
metadataTypeToParty[metadataType.Purchaser] = partyType.PURCHASER;

export default {
  name: "DocumentMetadata",
  props: {
    documentMetadata: Object,
    textSelection: Object,
    isLineItem: Boolean,
    focusOnLoad: Boolean,
    onClickToFill: Function,
    onPartyChanged: Function
  },
  components: {
    TextMetadata,
    NumericMetadata,
    PartyMetadata,
    DateMetadata,
    OrgUnitMetadata,
    ListMetadata,
    DateTimeMetadata,
  },
  data() {
    return {
      metadataType,
    };
  },

  computed: {
    partyTypeId() {
      return metadataTypeToParty[this.documentMetadata.metadata.metadataType.id];
    },
  },

  methods: {
    isMatch(args) {
      if (!this.documentMetadata) return false;

      let ids = Array.isArray(args) ? args : [args];
      let mtId = this.documentMetadata.metadata.metadataType.id;
      return ids.includes(mtId);
    },
  },
};
</script>
<style>
.metadata-label {
  color: #888;
  font-size: 12px;
  margin-bottom: 2px;
}
</style>

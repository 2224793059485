var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-4"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-email-open-outline")]),_vm._v(" Invitations ")],1),_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":true,"must-sort":true,"no-data-text":'',"headers":_vm.headers,"items":_vm.invitations,"loading":_vm.loading,"search":_vm.searchQuery},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [(item.expired)?_c('div',{staticStyle:{"margin-top":"2px","margin-bottom":"2px","color":"#ff5252"}},[_c('v-tooltip',{attrs:{"bottom":"","attach":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"color":"#ff5252"}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_vm._v(" This invitation has expired. Please request an invitation be resent from "+_vm._s(item.companyName)+". ")]),_vm._v(" EXPIRED ")],1):_vm._e(),_c('img',{attrs:{"src":(_vm.API_URL + "/logo/" + (item.senderCompanyId))}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlight(item.name, _vm.searchQuery))}})]}},{key:"item.buttons",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-spacer'),(!item.expired)?_c('v-btn',{staticClass:"ma-4",attrs:{"large":"","color":"primary","depressed":"","disabled":!_vm.isAdmin},on:{"click":function($event){item.isConnected ? _vm.additionalInvite(item) : _vm.accept(item)}}},[_vm._v("Accept")]):_vm._e(),(!item.expired)?_c('v-btn',{staticClass:"ma-4",attrs:{"large":"","color":"error","depressed":"","disabled":!_vm.isAdmin},on:{"click":function($event){return _vm.reject(item)}}},[_vm._v("Reject")]):_vm._e(),(item.expired)?_c('v-btn',{staticClass:"ma-4",attrs:{"large":"","color":"error","depressed":"","disabled":!_vm.isAdmin},on:{"click":function($event){return _vm.deleteInvitation(item)}}},[_vm._v("Delete")]):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
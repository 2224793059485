export default {
  Sent: 1,
  Received: 2,
  Approved: 3,
  Paid: 4,
  Rejected: 5,
  Resubmitted: 6,
  New: 7,
  Pending: 8,
  Returned: 9,
  Viewed: 10,
};

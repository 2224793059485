<template>
  <div>
    <LoadingProgress :loading="loading" />
    <div class="docvue-background">
      <img src="/img/landing-page.jpg" />
    </div>
    <v-flex style="margin-top: 7rem">
      <v-card class="elevation-1 card mt-4 pa-4 card-form justify-center" style="max-width: 800px; margin: 500px auto">
        <div v-if="!company.invitationToken" class="text-center pt-4 mt-4 mb-4 pb-4">
          Already have an account? <a href="javascript:void(0)" @click="login()">Login instead</a>
        </div>
        <div v-if="company.invitationToken" class="text-center pt-4 mt-4 mb-4 pb-4 title already-login">
          <a href="javascript:void(0)" @click="gotoInvitation()">Already have a login?</a>
        </div>
          <v-form ref="form" v-model="isValid">
            <v-flex class="ma-8">
              <h3 class="display-1 mb-4 pb-4">Sign Up</h3>
              <h3 class="headline mb-4">Account Details</h3>
              <v-text-field
                  :label="'Legal Name'"
                  v-model="company.legalName"
                  :rules="[requiredRule, companyNameRule]"
                  counter
                  :maxlength="250">
              </v-text-field>
              <v-text-field
                  :label="'Known As'"
                  v-model="company.knownAs"
                  counter
                  :maxlength="250">
              </v-text-field>
              <v-select
                  title="timezones"
                  v-model="company.timezone"
                  :items="timezoneItems"
                  item-text="displayName"
                  item-value="id"
                  label="Time Zone"
                  :rules="[requiredRule]">
              </v-select>
              <v-text-field
                  :label="'Address Line One'"
                  v-model="company.addressLineOne"
                  :rules="[requiredRule]"
                  counter
                  :maxlength="250">
              </v-text-field>
              <v-text-field
                  :label="'Address Line Two'"
                  v-model="company.addressLineTwo"
                  counter
                  :maxlength="250">
              </v-text-field>
              <v-text-field
                  :label="'Address Line Three'"
                  v-model="company.addressLineThree"
                  counter
                  :maxlength="250">
              </v-text-field>
              <v-select
                  title="countries"
                  v-model="selectedCountry"
                  :items="countryItems"
                  item-text="name"
                  item-value="code"
                  label="Select a Country"
                  :rules="[requiredRule]"
                  @change="onCountryChange()">
              </v-select>
              <v-select
                  title="states"
                  v-model="selectedState"
                  :items="availableStates"
                  item-text="name"
                  item-value="code"
                  label="Select a State"
                  :rules="[requiredRule]"
                  :disabled="!selectedCountry">
              </v-select>
              <v-text-field 
                  :label="'City'"
                  v-model="company.city"
                  counter
                  :rules="[requiredRule]"
                  :maxlength="250">
              </v-text-field>
              <v-text-field
                  :label="'Zip Code'"
                  :rules="zipCodeRules"
                  v-model="zipCodeUpper"
                  counter
                  :maxlength="10">
              </v-text-field>
              <v-select
                  title="taxTypeIds"
                  v-model="selectedTaxType"
                  :items="availableTaxTypes"
                  item-text="name"
                  item-value="code"
                  label="Tax Identification Type"
                  :rules="[requiredRule]"
                  :disabled="!selectedCountry">
              </v-select>
              <v-text-field
                  :label="'Tax Identification Number'"
                  :rules="[requiredRule]"
                  v-model="company.taxNumber"
                  :hint="taxFormat"
                   persistent-hint
                  counter
                  :maxlength="50">
              </v-text-field>
              <v-checkbox
                  :label="'Active'"
                  v-model="company.isActive">
              </v-checkbox>
              <h5 class="headline mb-3 mt-5">Administrative User</h5>
              <entity-form :entity="company" :inputFields="adminFields" />
            </v-flex>
          </v-form>
        <v-card-actions class="mr-4 mb-4">
          <v-spacer />
          <v-btn @click="save()" :disabled="!isValid" large color="primary" :loading="loading" block x-large depressed
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import ValidationRules from "@/validationRules";
import companyRegistrationService from "@/services/companyRegistrationService";
import servicesLookupService from "@/services/servicesLookupService";
import { getInstance } from "../auth/authWrapper";
import EntityForm from "@/components/EntityForm";
import LoadingProgress from "@/components/LoadingProgress.vue";

const ADMIN_FIELDS = [
  { label: "First Name", attribute: "userFirstName", type: "text", rules: [ValidationRules.requiredRule] },
  { label: "Last Name", attribute: "userLastName", type: "text", rules: [ValidationRules.requiredRule] },
  {
    label: "Email",
    attribute: "userEmail",
    type: "text",
    rules: [ValidationRules.requiredRule, ValidationRules.emailRule],
  },
];

export default {
  mixins: [ValidationRules],
  components: { EntityForm, LoadingProgress },
  name: "Register",
  props: {
    invitationToken: String,
    isBoarding: Boolean,
  },
  data() {
    return {
      loading: false,
      isValid: false,
      requiredRule: ValidationRules.requiredRule,
      companyNameRule: ValidationRules.companyNameRule,
      company: {
        invitationToken: this.invitationToken,
        isBoarding: this.isBoarding,
        legalName: "",
        taxType: 0,
        taxNumber: ""
      },
      adminFields: ADMIN_FIELDS,
      selectedCountry: null,
      selectedState: null,
      selectedTaxType: null,
      zipCode: null,
      timezones: [],
      countries: [],
      usStates: [],
      caProvinces: [],
      usTaxTypes: [],
      caTaxTypes: []
    }
  },

  computed: {
    isAuthenticated() {
      return this.$auth.isAuthenticated;
    },
    countryItems() {
      return this.countries;
    },
    timezoneItems() {
      return this.timezones;
    },
    availableStates() {
      const country = this.countries.find(c => c.code === this.selectedCountry);
      return country ? (country.code === "US" ? this.usStates : this.caProvinces) : []
    },
    availableTaxTypes() {
      const country = this.countries.find(c => c.code === this.selectedCountry);
      return country ? (country.code === "US" ? this.usTaxTypes : this.caTaxTypes) : []
    },
    zipCodeRules() {
      let rules = [ValidationRules.requiredRule];

      const country = this.countries.find(c => c.code === this.selectedCountry);

      if (country?.code === 'US')
          rules.push(ValidationRules.zipCodeRule);

      if (country?.code === 'CA')
          rules.push(ValidationRules.postalCodeRule);

      return rules;
    },
    zipCodeUpper: {
      get() {
        return this.zipCode;
      },
      set(value) {
        this.zipCode = value.toUpperCase();
      }
    },
    taxFormat() {
      if (this.selectedCountry && this.selectedTaxType) {
        var taxes = this.selectedCountry === "US" ? this.usTaxTypes : this.caTaxTypes;
        var tax = taxes.find(tax => tax.code === this.selectedTaxType.toString());
        return tax?.format;
      } else return null;
    },
  },

  async created() {
    this.loading = true;
    try {
      await this.loadInitialData();
    } finally {
      this.loading = false;
    }
  },

  methods: {
    async loadInitialData() {
      try {
        const [
          countriesResult,
          timezonesResult,
          usStatesResult,
          caProvincesResult,
          usTaxTypesResult,
          caTaxTypesResult,
        ] = await Promise.all([
          servicesLookupService.getCountries(),
          servicesLookupService.getTimezones(),
          servicesLookupService.getStates("US"),
          servicesLookupService.getStates("CA"),
          servicesLookupService.getTaxTypes("US"),
          servicesLookupService.getTaxTypes("CA")
        ]);

        this.countries = countriesResult;
        this.timezones = timezonesResult;
        this.usStates = usStatesResult;
        this.caProvinces = caProvincesResult;
        this.usTaxTypes = usTaxTypesResult.map(({ code, name, format }) => ({ code: code.toString(), name, format }));
        this.caTaxTypes = caTaxTypesResult.map(({ code, name, format }) => ({ code: code.toString(), name, format }));
      } catch (err) {
        this.$root.$snackbar.error(err.message);
      }
    },
    onCountryChange() {
      this.selectedState = null;
      this.selectedTaxType = null;
      this.zipCode = null;
    },
    login() {
      const authService = getInstance();
      authService.loginWithRedirect({ appState: { targetUrl: this.redirectTo || "/" } });
    },

    gotoInvitation() {
      const authService = getInstance();
      if (this.isBoarding)
        authService.loginWithRedirect({ appState: { targetUrl: `/admin/onboarding/${this.company.invitationToken}` } });
      else
        authService.loginWithRedirect({ appState: { targetUrl: `/admin/invitations/${this.company.invitationToken}` } });
    },

    async save() {
      this.loading = true;
      this.company.country = this.selectedCountry;
      this.company.state = this.selectedState;
      this.company.taxIdType = this.selectedTaxType;
      this.company.zipCode = this.zipCode;

      try {
        var company = await companyRegistrationService.registerCompany(this.company);
        this.$router.push({
          name: "RegisterSuccess",
          params: { companyName: company.name },
        });
      } catch (err) {
        this.loading = false;
        this.$root.$snackbar.error(err.response.data.error.message);
      }
    },
  },
};
</script>

<style scoped>
.already-login {
  background: #eee;
}
</style>

<template>
  <v-container fluid v-if="orgUnit">
    <v-card class="elevation-1 card pa-4 card-form">
      <v-form ref="form">
        <v-card-text>
          <v-row>
            <h3 class="headline mb-4">{{ isCreate ? "Create" : "Edit" }} {{ orgUnitTerminology }}</h3>
            <v-spacer />
            <div v-show="!isCreate">
              <ActionDropdown :actions="actions" :performAction="performAction" />
            </div>
          </v-row>
          <EntityForm :entity="orgUnit" :inputFields="ORGUNIT_FIELDS" />
        </v-card-text>
      </v-form>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="save()" :disabled="loading" large color="primary" depressed>Save</v-btn>
        <v-btn @click="cancel()" :disabled="loading" large depressed>Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import orgUnitService from "@/services/admin/orgUnitService";
import EntityForm from "@/components/EntityForm";
import ActionDropdown from "@/components/ActionDropdown";
import Auth0Mixin from "../Auth0Mixin";
import terminologyService from "@/services/terminologyService.js";
import terminology from "@/constants/terminology";
import lookupServices from "@/services/servicesLookupService.js";
import ValidationRules from "../../validationRules";


const ACTION_DELETE = "Delete";
const ACTION_SAVE = "Save";
const requiredRule = [v => !!v || "Required"];

export default {
  name: "OrgUnit",
  mixins: [Auth0Mixin],
  components: { EntityForm, ActionDropdown },
  data() {
    return {
      states: [],
      statesByCountry: [],
      taxes: [],
      taxesByCountry: [],
      orgUnit: null,
      headers: [{ text: "Name", value: "name" }],
      isCreate: false,
      loading: false,
      actions: [ACTION_SAVE, ACTION_DELETE],
      orgUnitTerminology: terminologyService.lookup(terminology.ORG_UNIT),      
      ORGUNIT_FIELDS: [
        { label: "Name", attribute: "name", rules: requiredRule, type: "text" },
        { label: "Code", attribute: "code", rules: requiredRule, type: "text",
          altText: "Used to quickly separate and identify your profile. This can be anything that is used internally within your company do identify this division, or as simple as the word 'Default'",
        }, 
        { label: "Known as", attribute: "knownAs", type: "text", maxlength: 250 },
        // Contact
        { label: "Phone number", attribute: "phone", type: "text" },

        // Main Address
        { label: "Main Address", attribute: "address", type: "header" },
        { label: "Address Line One", attribute: "addressLineOne", type: "text" },
        { label: "Address Line Two", attribute: "addressLineTwo", type: "text" },
        { label: "Address Line Three", attribute: "addressLineThree", type: "text", maxlength: 250 },
        { label: "Country", attribute: "country", type: "select", items: [], changeEvent: this.onMainAddressCountryChange },
        { label: "State", attribute: "state", type: "select" },
        { label: "City", attribute: "city", type: "text" },
        { label: "Zip Code", attribute: "zipCode", type: "text" },

        // Payment Address
        { label: "Payment Address", attribute: "paymentAddress", type: "header" },
        { label: "Address Line One", attribute: "paymentAddressLineOne", type: "text" },
        { label: "Address Line Two", attribute: "paymentAddressLineTwo", type: "text" },
        { label: "Address Line Three", attribute: "paymentAddressLineThree", type: "text", maxlength: 250 },
        { label: "Country", attribute: "paymentAddressCountry", type: "select", items: [], changeEvent: this.onPaymentAddressCountryChange },
        { label: "State", attribute: "paymentAddressState", type: "select" },
        { label: "City", attribute: "paymentAddressCity", type: "text" },
        { label: "Zip Code", attribute: "paymentAddressZipCode", type: "text" },

        // Tax Address
        { label: "Tax Address", attribute: "taxAddress", type: "header" },
        { label: "Address Line One", attribute: "taxAddressLineOne", type: "text" },
        { label: "Address Line Two", attribute: "taxAddressLineTwo", type: "text" },
        { label: "Address Line Three", attribute: "taxAddressLineThree", type: "text", maxlength: 250 },
        { label: "Country", attribute: "taxAddressCountry", type: "select", items: [], changeEvent: this.onTaxAddressCountryChange },
        { label: "State", attribute: "taxAddressState", type: "select" },
        { label: "City", attribute: "taxAddressCity", type: "text" },
        { label: "Zip Code", attribute: "taxAddressZipCode", type: "text" },
       
        // Taxes
        { label: "Tax", attribute: "tax", type: "header" },
        { label: "Tax Identification Type", attribute: "taxIdentificationType", type: "select" },
        { label: "Tax Identification Number", attribute: "taxIdentificationNumber", type: "text", maxlength: 250 },
        { label: "Tax ID Valid", attribute: "isTaxIdValid", type: "checkbox", readonly: true, disabled: true },
        { label: "Active", attribute: "isActive", type: "checkbox" },
      ],
    };
  },

  methods: {
    async init() {
      this.states = await lookupServices.getAllStates();
      this.taxes = await lookupServices.getAllTaxes();

      this.isCreate = this.$route.params.id === "create";

      if (this.isCreate) {
        this.orgUnit = {
          isActive: true,
        };
      } else {
        this.orgUnit = await orgUnitService.get(this.$route.params.id);
      }

      this.getCountries();

      this.setComputedMainAddressRules();
      this.setComputedPaymentAddressRules();
      this.setComputedTaxAddressRules();
    },

    async performAction(action) {
      if (action === ACTION_DELETE) {
        try {
          await orgUnitService.delete(this.orgUnit.id);
          this.$router.push(`/admin/orgunits`);
        } catch (err) {
          this.$root.$snackbar.exception(err);
        }
      }
      else if (action === ACTION_SAVE) {
        this.save();
      }
    },

    async save() {
      if (!this.isMainAddressValid) {
        this.$root.$snackbar.error("Valid address should contain Line one, City, Country, State and Zip/postal code");
        return;
      }
      if (!this.$refs.form.validate()) {
        return;
      }


      this.loading = true;

      try {
        if (this.isCreate) {
          await orgUnitService.create(this.orgUnit);
        } else {
          await orgUnitService.update(this.orgUnit);
        }
      } catch (ex) {
        this.loading = false;
        this.$root.$snackbar.exception(ex);
        return;
      }

      this.$root.$snackbar.message(`${this.orgUnitTerminology} ${this.isCreate ? "Created" : "Saved"}`);
      this.$router.push(`/admin/orgunits`);
    },

    getCountries() {
      lookupServices.getCountries().then((c) => {

        let countries = c.filter(x => x.isActive).map(x => { return { id: x.code, name: x.name } });
        this.ORGUNIT_FIELDS[8].items = countries;
        this.ORGUNIT_FIELDS[16].items = countries;
        this.ORGUNIT_FIELDS[24].items = countries;
        
        this.onMainAddressCountryChange(true);
        this.onPaymentAddressCountryChange(true);
        this.onTaxAddressCountryChange(true);

       });
    },

    onMainAddressCountryChange(keepValues) {

      if (!keepValues) {
        this.orgUnit.state = undefined;
        this.orgUnit.taxIdentificationType = undefined;
      }

      if(this.orgUnit?.country) {
        
        if (this.orgUnit.country == "US") {
          this.statesByCountry = this.states.usStates.map(x => { return { id: x.code, name: x.name } });
          this.taxesByCountry = this.taxes.usTaxTypes.map(x => { return { id: x.code.toString(), name: x.name } });
        }
        else if (this.orgUnit.country == "CA") {
          this.statesByCountry = this.states.caProvinces.map(x => { return { id: x.code, name: x.name } });
          this.taxesByCountry = this.taxes.caTaxTypes.map(x => { return { id: x.code.toString(), name: x.name } });
        }

        this.ORGUNIT_FIELDS[9].items = this.statesByCountry;
        this.ORGUNIT_FIELDS[29].items = this.taxesByCountry;

        this.setComputedMainAddressRules();
      }
    },

    onPaymentAddressCountryChange(keepValues) {

      if (!keepValues) {
        this.orgUnit.paymentAddressState = undefined;
      }

      if(this.orgUnit?.paymentAddressCountry) {

        if (this.orgUnit.paymentAddressCountry == "US") {
          this.statesByCountry = this.states.usStates.map(x => { return { id: x.code, name: x.name } });
          this.taxesByCountry = this.taxes.usTaxTypes.map(x => { return { id: x.code.toString(), name: x.name } });
        }
        else if (this.orgUnit.paymentAddressCountry == "CA") {
          this.statesByCountry = this.states.caProvinces.map(x => { return { id: x.code, name: x.name } });
          this.taxesByCountry = this.taxes.caTaxTypes.map(x => { return { id: x.code.toString(), name: x.name } });
        }

        this.ORGUNIT_FIELDS[17].items = this.statesByCountry;

        this.setComputedPaymentAddressRules();
      }
    },

    onTaxAddressCountryChange(keepValues) {

      if (!keepValues) {
        this.orgUnit.taxAddressState = undefined;
      }

      if(this.orgUnit?.taxAddressCountry) {

        if (this.orgUnit.taxAddressCountry == "US") {
          this.statesByCountry = this.states.usStates.map(x => { return { id: x.code, name: x.name } });
          this.taxesByCountry = this.taxes.usTaxTypes.map(x => { return { id: x.code.toString(), name: x.name } });
        }
        else if (this.orgUnit.taxAddressCountry == "CA") {
          this.statesByCountry = this.states.caProvinces.map(x => { return { id: x.code, name: x.name } });
          this.taxesByCountry = this.taxes.caTaxTypes.map(x => { return { id: x.code.toString(), name: x.name } });
        }

        this.ORGUNIT_FIELDS[25].items = this.statesByCountry;

        this.setComputedTaxAddressRules();
      }
    },

    cancel() {
      this.$router.push(`/admin/orgunits`);
    },
      
    setComputedMainAddressRules() {
      this.ORGUNIT_FIELDS[11].rules = this.mainAddressZipCodeRules;
      this.ORGUNIT_FIELDS[29].rules = this.taxIdTypeRules;
    },

    setComputedPaymentAddressRules() {
      this.ORGUNIT_FIELDS[19].rules = this.paymentAddressZipCodeRules;
    },

    setComputedTaxAddressRules() {
      this.ORGUNIT_FIELDS[27].rules = this.taxAddressZipCodeRules;
    },

    zipCodeRules(country) {
      let rules = [];

      if (country === 'US')
        rules.push(ValidationRules.zipCodeRule);

      if (country === 'CA')
        rules.push(ValidationRules.postalCodeRule);

      return rules;
    }
  },

  computed: {
    taxIdTypeRules() {
      return [
      (v) => ((v && v.length > 0) || this.orgUnit.taxIdentificationNumber === undefined || this.orgUnit.taxIdentificationNumber === "") || 
        "Required when Tax Identification Number is populated"
      ]
    },
    mainAddressZipCodeRules() {
      const country = this.orgUnit?.country;
      let rules = this.zipCodeRules(country);
      return rules;
    },
    paymentAddressZipCodeRules() {
      const country = this.orgUnit?.paymentAddressCountry;
      return this.zipCodeRules(country);
    },
    taxAddressZipCodeRules() {
      const country = this.orgUnit?.taxAddressCountry;
      return this.zipCodeRules(country);
    },
    isMainAddressValid() {
      let isAddressValid =
        (this.orgUnit.addressLineOne &&
          this.orgUnit.city &&
          this.orgUnit.zipCode &&
          this.orgUnit.country &&
          this.orgUnit.state) ||
        (!this.orgUnit.addressLineOne &&
          !this.orgUnit.city &&
          !this.orgUnit.zipCode &&
          !this.orgUnit.country &&
          !this.orgUnit.state);
      return isAddressValid;
    },
  },
};
</script>

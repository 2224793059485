<template>
  <v-container fluid>
    <v-card class="elevation-1 card mt-4 pa-4 card-form">
      <v-card-title class="pa-4">
        Accept Additional Invitation
      </v-card-title>
      <v-card-text v-if="currentStep === 1" class="wizard">
        <h4>Terms of Acceptance</h4>
        <p>
          By clicking continue, you agree that you have done any applicable due diligence specific to your company
          policies relevant to accepting documents from outside parties
        </p>
      </v-card-text>
      <v-card-text v-if="currentStep === 2" class="wizard">
        <h4>Existing or new Profile</h4>
        <v-radio-group label="Existing or new Profile" v-model="process" @change="onChangeProcess">
          <v-radio label="Select an Profile with existing connection (Recommended)" value="existing" />
          <v-radio label="Create a new Profile (Advanced)" value="new" />
        </v-radio-group>
      </v-card-text>
      <v-card-text v-if="currentStep === 3 && process === 'new'" class="wizard">
        <h4>Create New Profile</h4>
        <v-form ref="form" v-model="formValid">
          <v-text-field v-model="companyOrgUnit.name" label="Name" required></v-text-field>
          <v-text-field v-model="companyOrgUnit.code" label="Company Code" required></v-text-field>
          <h2 class="eform-subtitle">Address</h2>
          <v-text-field v-model="companyOrgUnit.addressLineOne" label="Address Line One"></v-text-field>
          <v-select
            v-model="selectedCountry"
            :items="countries"
            label="Country"
            item-text="name"
            item-value="code"
            @change="onCountryChange"
          ></v-select>
          <v-select
            v-model="selectedState"
            :items="states"
            label="State"
            item-text="name"
            item-value="code"
          ></v-select>
          <v-text-field v-model="companyOrgUnit.city" label="City"></v-text-field>
          <v-text-field v-model="companyOrgUnit.zipCode" :label="zipCodeLabelName" :rules="zipCodeRules"></v-text-field>
          <h2 class="eform-subtitle">Tax</h2>
          <v-select
            v-model="selectedTaxType"
            :items="taxTypes"
            label="Tax Identification Type"
            item-text="name"
            item-value="code"
          ></v-select>
          <v-text-field
            v-model="companyOrgUnit.taxIdentificationNumber"
            label="Tax Identification Number"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-text v-if="currentStep === 3 && process === 'existing'" class="wizard">
        <h4>Select Profile With Existing Connection</h4>
        <v-select
          v-model="selectedOrgUnit"
          :items="orgUnits"
          label="Profiles"
          item-text="name"
          item-value="id"
          @change="onChangeOrgUnit"
          required
        ></v-select>
      </v-card-text>
      <v-card-text v-if="currentStep === 4 && filteredParties.length > 1" class="wizard">
        <h4>Select Connection</h4>
        <v-select
          v-model="selectedParty"
          :items="filteredParties"
          label="Connection"
          item-text="displayName"
          item-value="id"
          required
        ></v-select>
      </v-card-text>
      <v-card-text v-if="currentStep === 4 && filteredParties.length === 0" class="wizard">
        <h2>Add new Connection</h2>
        <v-select
          v-model="newPartyType"
          :items="partyTypeList"
          label="Connection Type"
          item-text="name"
          item-value="id"
          required
        ></v-select>
        <v-text-field v-model="newPartyName" label="Name" required></v-text-field>
        <v-text-field v-model="newPartyCode" label="Number" required></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="prevStep" :disabled="currentStep === 1" large color="primary" depressed>Previous</v-btn>
        <v-btn @click="nextStep" :disabled="isNextButtonDisabled" large color="primary" depressed>Next</v-btn>
        <v-spacer />
        <v-btn @click="cancel" large color="primary" depressed>Cancel</v-btn>
        <v-btn @click="submit" large color="primary" depressed :disabled="!canAccept">Accept</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import servicesLookupService from "@/services/servicesLookupService";
import invitationService from "@/services/invitationService";
import companyOrgUnitService from "@/services/admin/orgUnitService";
import partyService from "@/services/partyService";
import ValidationRules from "../../validationRules";
import userService from "@/services/userService";
import partyTypeList from "@/constants/partyTypeList";

const ZIP_CODE = "Zip Code";
const POSTAL_CODE = "Postal Code";

export default {
  name: "AdditionalInvitation",
  props: {
    invitationId: Number,
    invitation: Object,
  },
  data() {
    return {
      currentStep: 1,
      process: "existing",
      companyOrgUnit: {
        name: "",
        code: "",
        addressLineOne: "",
        city: "",
        zipCode: "",
        country: "",
        state: "",
        taxIdentificationType: "",
        taxIdentificationNumber: "",
      },
      selectedCountry: null,
      selectedState: null,
      selectedTaxType: null,
      selectedOrgUnit: null,
      selectedParty: null,
      countries: [],
      states: [],
      taxTypes: [],
      orgUnits: [],
      parties: this.invitation.parties || [],
      filteredParties: [],
      formValid: false,
      country: null,
      state: null,
      taxType: null,
      zipCodeRules: [],
      partyTypeList,
      newPartyType: null,
      newPartyName: this.invitation.companyName,
      newPartyCode: null,
    };
  },
  computed: {
    ...mapGetters(["selectedCompanyId"]),
    isNextButtonDisabled() {
      let isButtonDisabled = !this.canProceedToNextStep;

      if (this.currentStep === 3 && !isButtonDisabled) {
        isButtonDisabled = this.selectedParty != null;
      }

      if (this.currentStep === 4) {
        isButtonDisabled = true;
      }

      return isButtonDisabled;
    },
    canProceedToNextStep() {
      let canProceed = false;
      switch (this.currentStep) {
        case 1:
          canProceed = true;
          break;
        case 2:
          canProceed = true;
          break;
        case 3:
          if (this.isNewProcess) {
            canProceed = this.isOrgUnitValid;
          }
          if (this.isExistingProcess) {
            canProceed = !!this.selectedOrgUnit;
          }
          break;
        case 4:
          canProceed = !!this.selectedParty || this.canCreateNewParty;
          break;
      }

      return canProceed;
    },
    canAccept() {
      return (
        this.isNextButtonDisabled && this.canProceedToNextStep && (this.selectedParty != null || this.canCreateNewParty)
      );
    },
    isOrgUnitValid() {
      return !!this.companyOrgUnit.name && !!this.companyOrgUnit.code;
    },
    isNewProcess() {
      return this.process === "new";
    },
    isExistingProcess() {
      return this.process === "existing";
    },
    zipCodeLabelName(){
      if (this.selectedCountry == null)
        return ZIP_CODE;
      
      return this.selectedCountry == "US" ? ZIP_CODE : POSTAL_CODE;
    },
    canCreateNewParty() {
      return this.newPartyType > 0 && !!this.newPartyCode;
    }
  },
  methods: {
    async init() {
      this.clearCompanyOrgUnit();
      this.currentStep = 1;
      this.process = "existing";
      this.locations = [];
      this.countries = [];
      this.taxTypes = [];
      this.states = [];
      this.selectedCountry = null;
      this.selectedState = null;
      this.selectedTaxType = null;
      this.parties = this.invitation.parties || [];
      this.selectedParty = null;
      this.country = null;
      this.state = null;
      this.taxType = null;
      
      this.partyIdsConnectedToSamePartyType = await invitationService.getPartyIdsOfConnectionsFromSamePartyType(this.invitation);

      let uniqueOrgUnitIds = [...new Set(this.invitation.parties.map(p => p.companyOrgUnitIds).flat())];
      const orgUnitsResponse = await companyOrgUnitService.getList(true, 1, 10000, uniqueOrgUnitIds.join(','));
      this.orgUnits = orgUnitsResponse.items;

      this.populateOrgUnitNamesOfParties();

      this.locations = await servicesLookupService.getLocations();

      this.countries = Array.from(new Set(this.locations.map(item => JSON.stringify(item.country)))).map(jsonItem =>
        JSON.parse(jsonItem)
      );
    },
    cancel() {
      this.$router.push({ name: "InvitationList" });
    },
    nextStep() {
      if (this.currentStep === 3 && this.isNewProcess) {
        if (this.isValidZipCode())
          this.currentStep++;
      } else {
        this.currentStep++;
      }
    },
    prevStep() {
      if (this.currentStep === 4) {
        this.selectedParty = null;
      }
      
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    async submit() {
      let answer = await this.$root.$confirmDialog.open(
        "Confirm",
        "Do you want to accept the additional request?",
        "Continue"
      );

      if (!answer) return;

      this.country = this.selectedCountry ? this.countries.find(c => c.code == this.selectedCountry) : null;
      this.state = this.selectedState ? this.states.find(e => e.code == this.selectedState) : null;
      this.taxType = this.selectedTaxType ? this.taxTypes.find(t => t.code == this.selectedTaxType) : null;

      if (this.isNewProcess) {
        await this.runNewProcess();
      } else {
        try {
          const party = this.parties.find(p => p.id == this.selectedParty);
          
          let invitationAcceptance = {
            id: this.invitationId,
            existingPartyId: party.id,
            partyTypeId: party.partyTypeId,
            partyNumber: party.number,
            orgUnitIds: [this.companyOrgUnit.id],
          };

          await this.acceptAdditionalInvite(invitationAcceptance);
        } catch (err) {
          this.$root.$snackbar.error(err.response?.data?.error?.message || "Couldn't accept invitation");
        }
      }
    },
    onChangeOrgUnit() {
      if (this.selectedOrgUnit) {
        this.companyOrgUnit = this.orgUnits.find(e => e.id == this.selectedOrgUnit);
        this.filteredParties = this.parties.filter(p => p.companyOrgUnitIds.some(id => id == this.selectedOrgUnit));
        this.selectedParty = this.filteredParties.length === 1 ? this.filteredParties[0].id : null;
      } else {
        this.clearCompanyOrgUnit();
      }
    },
    onChangeProcess() {
      this.clearCompanyOrgUnit();
      this.selectedOrgUnit = null;
      this.selectedCountry = null;
      this.selectedState = null;
      this.selectedTaxType = null;

      if (this.isNewProcess)
        this.filteredParties = this.parties.filter(p => this.partyIdsConnectedToSamePartyType.includes(p.id));
      else
        this.filteredParties = [];
      this.selectedParty = this.filteredParties.length === 1 ? this.filteredParties[0].id : null;
    },
    async acceptAdditionalInvite(invitationAcceptance) {
      try {
        await invitationService.accept(invitationAcceptance);
        const exchangeUser = await userService.getUserByExternalId(
          decodeURI(this.$auth.user.sub), this.selectedCompanyId
        );
        this.$store.dispatch("documentTypeStore/updateDocumentTypes", { documentTypes: exchangeUser.documentTypes });
        this.$root.$snackbar.message("You are now connected to " + this.invitation.companyName);
        this.$store.dispatch("invitationStore/removePendingInvitation");
        this.$root.$emit("invitation.count.reload");
        this.$router.push({ name: "InvitationList" });
      } catch (err) {
        this.$root.$snackbar.error(err.response?.data?.error?.message || "Couldn't accept invitation");
      }
    },
    async runNewProcess() {
      this.companyOrgUnit.country = this.country ? this.country.code : null;
      this.companyOrgUnit.state = this.state ? this.state.code : null;
      this.companyOrgUnit.taxIdentificationType = this.taxType ? this.taxType.code : null;
      this.companyOrgUnit.taxIdentificationNumber = this.companyOrgUnit.taxIdentificationNumber
        ? this.companyOrgUnit.taxIdentificationNumber.toUpperCase()
        : null;

      try {
        const companyResult = await companyOrgUnitService.create(this.companyOrgUnit);
        let party;
        if (this.selectedParty) {
          party = this.parties.find(p => p.id == this.selectedParty);
          party = { ...party, id: null, companyOrgUnitIds: [companyResult.id] };
        } else {
          party = {
            name: this.newPartyName,
            number: this.newPartyCode,
            partyTypeId: this.newPartyType,
            connectedCompanyId: this.invitation.senderCompanyId,
            suggestedCompanyId: this.invitation.senderCompanyId,
            addressLineOne: this.invitation.senderAddressLineOne,
            city: this.invitation.senderCity,
            state: this.invitation.state,
            zipCode: this.invitation.senderZipCode,
            addressLineTwo: this.invitation.senderAddressLineTwo,
            companyOrgUnitIds: [companyResult.id],
            isActive: 1,
          };
        }
        const partyResult = await partyService.create(party);

        let invitationAcceptance = {
          id: this.invitationId,
          existingPartyId: partyResult.id,
          partyTypeId: partyResult.partyTypeId,
          partyNumber: partyResult.number,
          orgUnitIds: [companyResult.id],
        };

        await this.acceptAdditionalInvite(invitationAcceptance);
      } catch (err) {
        this.$root.$snackbar.error(err.response?.data?.error?.message || "Couldn't add Profile");
      }
    },
    async updateLocations() {
      const location = this.selectedCountry
        ? this.locations.find(item => item.country.code === this.selectedCountry)
        : "US";

      if (location) {
        this.states = location.states || [];
        this.taxTypes = location.taxTypes || [];
      } else {
        this.states = [];
        this.taxTypes = [];
      }
    },
    updateZipCodeRule() {
      if (this.selectedCountry == "US")
        this.zipCodeRules = [ValidationRules.zipCodeRule];

      if (this.selectedCountry == "CA")
        this.zipCodeRules = [ValidationRules.postalCodeRule];
    },
    isValidZipCode() {
      let isValid = true;
      
      if (this.companyOrgUnit.zipCode != "" && this.selectedCountry == null) {
        isValid = false;
        this.$root.$snackbar.error("A country must be selected when the Zip Code is populated");
      }
      else if (!this.$refs.form.validate()) {
        isValid = false;
        let zipCodeName = this.selectedCountry == "US" ? ZIP_CODE : POSTAL_CODE;
        this.$root.$snackbar.error("Please fix the " + zipCodeName + " issue");
      }
      
      return isValid;
    },  
    async onCountryChange(selected) {
      this.selectedCountry = selected;
      this.selectedState = null;
      this.selectedTaxType = null;
      this.companyOrgUnit.zipCode = "";
      this.updateZipCodeRule();
      await this.updateLocations();
    },
    clearCompanyOrgUnit() {
      this.companyOrgUnit = {
        name: "",
        code: "",
        addressLineOne: "",
        city: "",
        zipCode: "",
        country: "",
        state: "",
        taxIdentificationType: "",
        taxIdentificationNumber: "",
      };
    },
    populateOrgUnitNamesOfParties() {
      if (this.parties.length > 1) {
        this.parties.forEach(p => {
          let names = [];
          p.companyOrgUnitIds.forEach(id => {
            let orgUnit = this.orgUnits.find(x => x.id == id);
            names.push(orgUnit.name);
          });
          p.orgUnitNames = names.join(", ");
          p.displayName = p.name + " (" + p.orgUnitNames + ")";
        });
      }
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style scoped>
.wizard {
  height: 100%;
}
</style>

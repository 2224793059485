<template>
  <div></div>
</template>

<script>
import companyService from "@/services/companyService.js";

export default {
  props: {
    companyId: Number,
  },
  data() {
    return {
      notifications: [],
      pages: 1,
      currentPage: 1,
    };
  },
  async mounted() {
    var company = await companyService.getCompanyByPakServiceId(this.companyId);
    window.location.href = company.url;
  },
};
</script>

<template>
  <v-dialog v-model="show" persistent max-width="600">
    <v-card>
      <v-card-text class="title pt-4">Welcome to DocVue Exchange</v-card-text>
      <v-card-text class="pt-4"
        >Would you like to take a moment to upload your Company Logo, W9 and Insurance Certificate?</v-card-text
      >
      <v-card-text class="pt-4">
        <v-checkbox v-model="dontDisplayAgain" label="Do not show this message again"></v-checkbox>
      </v-card-text>
      <v-card-text class="pt-4">
        <v-btn color="primary" @click="ok">TAKE ME TO LOGO</v-btn>
        <v-btn color="grey" text @click="cancel" data-cy="cancel">Skip for now</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import userService from "@/services/userService";

export default {
  name: "WelcomeDialog",

  props: {
    show: Boolean,
    close: Function,
  },

  data: () => ({
    dontDisplayAgain: false,
  }),
  methods: {
    ok() {
      this.close(true);

      if (this.dontDisplayAgain) {
        userService.updateWelcomeMessageDisplayed();
      }
    },

    cancel() {
      this.close(false);
      if (this.dontDisplayAgain) {
        userService.updateWelcomeMessageDisplayed();
      }
    },
  },
};
</script>

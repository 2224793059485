import status from "./documentRouteStatus";

export default [
  { id: status.Sent, name: "Sent", color: "#4facd1", icon: "mdi-send" },
  { id: status.Received, name: "Received", color: "#4facd1", icon: "mdi-check" },
  { id: status.Approved, name: "Approved", color: "#229d22" },
  { id: status.Paid, name: "Paid", color: "#229d22", icon: "mdi-currency-usd" },
  { id: status.Rejected, name: "Rejected", color: "#a50a0a", icon: "mdi-alert-circle-outline" },
  { id: status.Resubmitted, name: "Resubmitted", color: "#4facd1", icon: "mdi-refresh" },
  { id: status.New, name: "New", color: "#4facd1", icon: "mdi-new-box" },
  { id: status.Pending, name: "Pending", color: "#4facd1", icon: "pending" },
  { id: status.Returned, name: "Returned", color: "#4facd1", icon: "assignment_return" },
  { id: status.Viewed, name: "Viewed", color: "#229d22", icon: "mdi-eye" },
];

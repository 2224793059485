import httpService from "./httpService";

export default {
  deleteUser: async id => httpService.deleteRequest(`/users/${id}`),
  createUser: async user => httpService.post("/users", user),
  getUser: async id => httpService.get(`/users/${id}`),
  getRoles: async id => httpService.get(`/users/${id}/roles`),
  getUserByExternalId: async (id, companyId) =>
    httpService.get(`/exchangeusers/external/${id}${companyId ? "?companyId=" + companyId : ""}`),
  updateWelcomeMessageDisplayed: async () => httpService.post(`/exchangeusers/welcomeMessageDisplayed`),
  getUserList: async () => httpService.get(`/users/exchange`),
  updateUser: async user => httpService.put(`/users/${user.id}`, user),
  updateEmailAddress: async user => httpService.post(`/users/${user.id}/emailaddress`, user),
  blockUser: async id => httpService.post(`/users/${id}/block`),
  unblockUser: async id => httpService.post(`/users/${id}/unblock`),
  getUserPersonalization: async () => httpService.get(`/users/current/personalization`),
  getCurrent: async () => httpService.get(`/users/current`),
  saveUserPersonalization: async profileItems => httpService.put(`/users/current/Personalization`, profileItems),
};

import httpService from "./httpService";

export default {
  getPending: async () => httpService.get(`/invitation/pending`),
  accept: async invitation => httpService.post(`/invitation/accept`, invitation),
  reject: async id => httpService.post(`/invitation/reject/${id}`),
  delete: async id => httpService.deleteRequest(`/invitation/delete/${id}`),
  register: async invitationToken => httpService.post(`/invitation/register/${invitationToken}`),
  getInvitationByToken: async invitationToken => httpService.get(`/invitation/getbytoken/${invitationToken}`),
  resend: async (partyId, email) =>
    httpService.post(`/invitation/resend`, {
      senderPartyIdOfReceiverCompany: partyId,
      receiverEmail: email,
    }),
  onBoard: async invitationToken => httpService.post(`/invitation/onboard/${invitationToken}`),
  getInvitationById: async id => httpService.get(`/invitation/getbyid/${id}`),
  getPartyIdsOfConnectionsFromSamePartyType: async (inv) =>
    httpService.get(`/invitation/partyIdsOfConnectionsFromSamePartyType`, {
      senderCompanyId: inv.senderCompanyId,
      receiverCompanyId: inv.receiverCompanyId,
      senderPartyTypeOfReceiverCompany: inv.senderPartyTypeOfReceiverCompany,
    }),
};

<template>
  <v-container fluid>
    <v-flex class="ma-4">
      <v-card class="elevation-1 card mt-4 pa-4 card-form">
        <LoadingProgress :loading="loading" />
          <v-form ref="form">
            <v-card-text>
              <v-row><h3 class="headline mb-4">{{ isCreate ? "Create" : "Edit" }} ACH</h3></v-row>
              <v-text-field
               label="Bank Name"
               v-model="ach.bankName"
               counter
               :rules="[requiredRule]"
               :maxlength="255"
              ></v-text-field>
              <v-text-field 
                label="Routing Number"
                v-model="ach.routingNumber"
                counter
                :rules="[requiredRule, numericRule]"
                :maxlength="255"
              ></v-text-field>
              <v-text-field
                label="Account Number"
                v-model="ach.accountNumber"
                counter
                :rules="[requiredRule, numericRule]"
                :maxlength="255"
              ></v-text-field> 
              <v-select 
                label="Account Type"
                v-model="ach.accountTypeId"
                :items="accountTypes"
                item-text="name"
                item-value="id"
                :rules="[simpleRequiredRule]"
              ></v-select>
              <v-select 
                label="Profile"
                v-model="orgUnitIds"
                :items="orgUnitOptions"
                item-text="name"
                item-value="id"
                multiple
                chips
                persistent-hint
                :rules="[requiredRule]"
              ></v-select>
              <v-select
                label="Connection"
                v-model="partyIndexes"
                :items="partyOptions"
                item-text="name"
                item-value="index"
                multiple
                chips
                persistent-hint
                :rules="[requiredRule]"
              ></v-select>
            </v-card-text>
          </v-form>

          <v-card-actions>
            <v-spacer />
              <v-btn @click="save()" :disabled="loading" large color="primary" depressed>Save</v-btn>
              <v-btn @click="cancel()" :disabled="loading" large depressed>Cancel</v-btn>
          </v-card-actions>
      </v-card>
    </v-flex>
  </v-container>
</template>

<script>
import Auth0Mixin from "../Auth0Mixin";
import LoadingProgress from "@/components/LoadingProgress";
import orgUnitService from "../../services/admin/orgUnitService";
import AccountTypeList from "../../constants/accountTypeList";
import partyService from "../../services/partyService";
import ValidationRules from "../../validationRules";
import achService from "../../services/achService";

export default {
  name: "Ach", 
  mixins: [Auth0Mixin], 
  components: { LoadingProgress }, 
  data() {
    return {
      ach: {
        bankName: null,
        routingNumber: null,
        accountNumber: null,
        accountTypeId: null,
        partiesByOrgUnitId: []
      },
      accountTypes: AccountTypeList,
      orgUnitOptions: [],
      partyOptions: [],
      orgUnitIds: [],
      partyIndexes: [],
      requiredRule: ValidationRules.requiredRule,
      simpleRequiredRule: v => !!v || "Required",
      numericRule: ValidationRules.numeric,
      isCreate: false,
      loading: false,
    };
  },
  methods: {
    async init() {
      this.loading = true;
            
      const orgUnits = await orgUnitService.getList(true);
      this.orgUnitOptions = orgUnits.items
        .filter(orgUnit => orgUnit.isActive === true)
        .map(o => ({ id: o.id, name: o.name }));

      this.isCreate = this.$route.params.id === "create";
      
      if (!this.isCreate) {
        let selectedAch = await achService.get(this.$route.params.id);
        
        this.ach.id = selectedAch.id;
        this.ach.isActive = selectedAch.isActive;
        this.ach.bankName = selectedAch.bankName;
        this.ach.accountTypeId = selectedAch.accountTypeId;
        this.orgUnitIds = selectedAch.partiesByOrgUnitId.map(pair => pair.key);
        
        await this.populateParties(this.orgUnitIds);
        
        selectedAch.partiesByOrgUnitId.forEach(pair => {
          pair.value.forEach(party => {
            let foundParty = this.partyOptions.find(p => p.id == party.id && p.orgUnitId == pair.key);
            if (foundParty)
              this.partyIndexes.push(foundParty.index)
          });
        });
      }
            
      this.loading = false;
    }, 
    
    async populateParties(orgUnitIds) {
      if (orgUnitIds.length > 0) {
        let ids = orgUnitIds.join();
        let partiesGroupedByOrgUnitId = await partyService.getPartiesByOrgUnitId(ids);

        let selectedParties = this.partyOptions.filter(party => this.partyIndexes.includes(party.index));
        this.partyOptions = [];
        let index = 0;

        partiesGroupedByOrgUnitId.forEach(pair => {
          let orgUnit = this.orgUnitOptions.find(x => x.id == pair.key);
          let parties = pair.value;

          parties.forEach(party => {
            party.name += " (" + orgUnit.name + ")";
            party.orgUnitId = orgUnit.id;
            party.index = index;
            index++;
          });

          this.partyOptions.push(...parties);
        });

        if (this.partyIndexes.length > 0) {
          let filteredParties = this.partyOptions.filter(party => selectedParties.some(sp => sp.id == party.id && sp.orgUnitId == party.orgUnitId));
          this.partyIndexes = filteredParties.map(party => party.index);
        }
      } else {
        this.partyOptions = [];
      }      
    },
    
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;
      
      this.ach.partiesByOrgUnitId = [];
      this.orgUnitIds.forEach(orgUnitId => {
        let parties = this.partyOptions.filter(party => party.orgUnitId == orgUnitId && this.partyIndexes.includes(party.index));
        this.ach.partiesByOrgUnitId.push({key: orgUnitId, value: parties});
      });

      try {
        if (this.isCreate) {
          await achService.create(this.ach);
          this.$root.$snackbar.message("ACH Saved");
        } else {
          let uAch = this.ach;
          await achService.update(uAch);
          this.$root.$snackbar.message("ACH Updated");
        }
        this.$router.push("/admin/achs");
      } catch (err) {
        this.loading = false;
        this.$root.$snackbar.exception(err);
      }
    },
    
    cancel() {
      this.$router.push("/admin/achs");
    },
  },
  watch: {
    "orgUnitIds": async function(orgUnitIds) {
      await this.populateParties(orgUnitIds);
    }, 
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"org-select"},[_c('div',{staticClass:"metadata-label"},[_vm._v(_vm._s(_vm.documentMetadata.metadata.name))]),_c('vselect',{ref:"textField",attrs:{"placeholder":"Type to search by name or code...","disabled":_vm.documentMetadata.metadata.isReadonly,"options":_vm.parties,"label":"name","reduce":function (p) { return (p ? p.id : -1); },"data-cy":_vm.documentMetadata.metadata.key},on:{"search":_vm.onSearch},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var number = ref.number;
var addressLineOne = ref.addressLineOne;
var city = ref.city;
var state = ref.state;
var companyOrgUnits = ref.companyOrgUnits;
return [_c('div',{staticClass:"pa-1"},[_c('b',[_vm._v(_vm._s(name))]),_c('div',{staticClass:"party-option-details"},[_vm._v(_vm._s(number)+" "+_vm._s(_vm.formatAddress({ addressLineOne: addressLineOne, city: city, state: state })))]),(_vm.showOrgUnit)?_c('div',{staticClass:"party-option-details"},[_vm._v(_vm._s(companyOrgUnits))]):_vm._e()])]}},{key:"no-options",fn:function(ref){return [_vm._v(" Sorry, no matching "+_vm._s(_vm.documentMetadata.metadata.name)+" ")]}}]),model:{value:(_vm.documentMetadata.value),callback:function ($$v) {_vm.$set(_vm.documentMetadata, "value", $$v)},expression:"documentMetadata.value"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.party),expression:"party"}],staticClass:"party-address"},[_vm._v(_vm._s(_vm.party ? _vm.party.number : "")+" "+_vm._s(_vm.formatAddress(_vm.party)))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }